import React from "react"
import Layout from "../../components/layout";
import Seo from "../../components/SEO/Seo"
import "../../styles/servicios.scss"



export default function Servicios(props) {

    return (
            <Layout>
                <Seo title="Web, Aplicaciones y Proyectos digitales" pathname={props.location.pathname}/>
                <div className="cuerpo-servicios">
                    <section className="container primera-seccion cabecera-servicios">
                        <div className="caja-titulo">
                            <h1>Web, Aplicaciones y Proyectos digitales</h1>
                        </div>
                        <div className="caja-subtitulo">
                            <p className="destacado">Una página web ya no es algo que se tiene por tener, es una auténtica lancha fueraborda digital.</p>
                        </div>
                    </section>               
                    <section className="container textos-servicio">
                        <div className="columna-foto" style={{backgroundImage: "url(/img/brujula.webp)"}}></div>
                        <div className="columna-texto">
                            <p>Las webs ya no son algo que se hace por hacer. 
                            Son lanchas fueraborda digitales.
                            <br/>
                            Herramientas de marketing que ayudan a navegar a tu negocio.
                            Nuestra propuesta: crear un sitio pensado para ti que mejore la 
                            imagen de tu empresa y te genere leads (contactos) y convertirlos 
                            en ventas y clientes fidelizados.</p>
                            <h3>Atraer visitas</h3>
                            <p>Tu web estará pensada para que te encuentren. 
                            Hoy día tu web debe ser rápida y ligera como 
                            una fueraborda (SEO técnico), con un contenido 
                            optimizado para buscadores (SEO de contenidos)
                            y visualizable desde cualquier dispositivo (mobile friendly).
                            </p>
                            <h3>Generar interés y confianza</h3>
                            <p>Una vez conseguidas las visitas tenemos que engancharlas: 
                            ser atractivos y persuasivos. Para esto tenemos la mejor fórmula:
                            personalizar siempre el diseño de tu sitio web y presentar
                            unos contenidos geniales. 
                            Y tu web debe ser seguro: si no das seguridad no serás confiable.</p>
                            <h3>Llamar a la acción</h3>
                            <p>La web convencerá y un diseño UX preparado hará que
                            los visitantes interesados contacten fácilmente:
                            landing page optimizadas, formularios y CTA.</p>
                            <h3>Facilitar la conversión y fidelización</h3>
                            <p>Tu web ha hecho su trabajo. Si tu equipo comercial 
                            y tus productos o servicios son buenos, habrás conseguido
                            una venta. Aquí deberías complementar tu web con una 
                            estrategia de marketing en Bullhost.
                            </p>
                        </div>
                    </section>
                </div>
    </Layout>
    )
}